import React from "react";
import styled from "styled-components";
import { Github, Twitter } from "../components/Social";
import { PageLayout } from "../components/Layout";
import Logo from "../components/Logo";

const SectionLayout = styled.div`
  padding: 16px 0;
`;

const Skills = () => (
  <SectionLayout>
    <Columns>
      <Column>
        <WorkplaceSection title="Skills">
          <List>
            <ListItem>UI Development</ListItem>
            <ListItem>Full-stack Software Development</ListItem>
            <ListItem>Learning</ListItem>
            <ListItem>Mentoring & Leadership</ListItem>
            <ListItem>Systems & Software Architecture</ListItem>
            <ListItem>Communication & Empathy</ListItem>
            <ListItem>Synthesizing Complexity</ListItem>
          </List>
        </WorkplaceSection>
      </Column>
      <Column>
        <WorkplaceSection title="Technology">
          <List>
            <ListItem>Modern JavaScript</ListItem>
            <ListItem>React</ListItem>
            <ListItem>Build & Development Tools</ListItem>
            <ListItem>Node.js</ListItem>
            <ListItem>Docker</ListItem>
            <ListItem>Jest, ESLint & static analysis</ListItem>
            <ListItem>SQL & Document Databases</ListItem>
          </List>
        </WorkplaceSection>
      </Column>
      <Column>
        <WorkplaceSection title="Secondary Skills">
          <List>
            <ListItem>Design</ListItem>
            <ListItem>Customer Thinking</ListItem>
            <ListItem>Timelines & Estimates</ListItem>
            <ListItem>Analytics & Descriptive Statistics</ListItem>
            <ListItem>Product Management - Agile</ListItem>
            <ListItem>Working Directly With Customers</ListItem>
          </List>
        </WorkplaceSection>
      </Column>
    </Columns>
    <Columns>
      <Column>
        <WorkplaceSection title="Recognition & Awards">
          <List>
            <ListItem>Domo Internet of Things Hack - Best Overall</ListItem>
            <ListItem>Domo Engineer of the Arbitrary Time Period*</ListItem>
            <ListItem>Domo Employee of the Arbitrary Time Period*</ListItem>
            <ListItem>
              Domo Prom King - A peer-selected award at end of year celebration.
            </ListItem>
            <ListItem>Riser Media - Employee of the Year</ListItem>
            <ListItem>
              Riser Media - peer-selected &quot;Most likely to invent something
              huge.&quot;
            </ListItem>
            <ListItem>12 Recommendations available on LinkedIn</ListItem>
          </List>
        </WorkplaceSection>
      </Column>

      <Column>
        <WorkplaceSection title="Technical History">
          <List>
            <ListItem>Objective-C & iOS Development</ListItem>
            <ListItem>git & SVN</ListItem>
            <ListItem>Express.js & Node CLIs</ListItem>
            <ListItem>Angular, Backbone.js & Marionette</ListItem>
            <ListItem>jQuery, MooTools & Prototype</ListItem>
            <ListItem>Adobe Flash & ActionScript 2/3</ListItem>
            <ListItem>CSS Preprocessors & Compass</ListItem>
            <ListItem>Grunt & Gulp</ListItem>
            <ListItem>require.js</ListItem>
            <ListItem>Java, GWT & Play Framework</ListItem>
            <ListItem>MySQL, MongoDB & Firebase</ListItem>
            <ListItem>PHP, Kohana, Code Igniter & Wordpress</ListItem>
            <ListItem>Apache2 & NGINX</ListItem>
            <ListItem>Sentry & Adobe Digital Marketing Cloud</ListItem>
            <ListItem>Jenkins & Drone - Continuous Integration</ListItem>
            <ListItem>Nanoc & Docpad - Static Site Generators</ListItem>
          </List>
        </WorkplaceSection>
      </Column>
      <Column>
        <WorkplaceSection title="Current Interests & Focus">
          <List>
            <ListItem>Web Assembly</ListItem>
            <ListItem>Programming Language Research</ListItem>
            <ListItem>Functional Programming</ListItem>
            <ListItem>Static Type Systems - Sound Types & Inference</ListItem>
            <ListItem>Static Program Verification</ListItem>
            <ListItem>Resilient Distributed Systems</ListItem>
            <ListItem>Statistics, Math & Data Science</ListItem>
            <ListItem>
              Framework & Platform Agnostic Shared User Interface Code
            </ListItem>
          </List>
        </WorkplaceSection>
      </Column>
    </Columns>
  </SectionLayout>
);

const ColumnsLayout = styled.div`
  display: flex;
  @media only screen and (max-width: 736px) {
    flex-direction: column;
  }
`;

const Columns = ({ children }) => (
  <AvoidBreak>
    <ColumnsLayout>{children}</ColumnsLayout>
  </AvoidBreak>
);

const Column = styled.div`
  flex: 1;
  margin-right: 16px;
`;

const AvoidBreak = styled.div`
  page-break-inside: avoid;
`;

const Bullet = ({ style }) => (
  <svg
    width="8px"
    height="8px"
    viewBox="0 0 24 24"
    version="1.1"
    xmlns="http://www.w3.org/2000/svg"
    xmlnsXlink="http://www.w3.org/1999/xlink"
    style={style}
  >
    <defs />
    <g id="Page-1" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
      <polygon
        id="Rectangle"
        fill="var(--title-color)"
        transform="translate(0.313708, 12.313708) rotate(-225.000000) translate(-0.313708, -12.313708) "
        points="-7.6862915 4.3137085 8.3137085 4.3137085 -7.6862915 20.3137085"
      />
    </g>
  </svg>
);

const ListLayout = styled.div`
  padding-left: 8px;
`;

const List = ({ children }) => (
  <AvoidBreak>
    <ListLayout>{children}</ListLayout>
  </AvoidBreak>
);

const ListItemLayout = styled.div`
  margin: 4px 0;
  display: flex;
  line-height: 1.4em;
`;

const ListItemElement = styled.div`
  padding-right: 4px;
  display: inline;
`;

const ListItem = ({ children }) => (
  <ListItemLayout>
    <ListItemElement>
      <div style={{ marginTop: -1 }}>
        <Bullet />
      </div>
    </ListItemElement>
    <div>{children}</div>
  </ListItemLayout>
);

const WorkplaceLayout = styled.div`
  display: flex;
  padding: 16px 0;
  @media only screen and (max-width: 736px) {
    flex-direction: column;
  }
`;
const WorkplaceTitle = styled.div`
  color: var(--title-color);
  font-size: 18px;
  font-weight: 600;
  line-height: 1;
  margin-bottom: 16px;
`;
const WorkplaceDuration = styled.div`
  font-size: 18px;
  color: var(--sub-title-color);
  white-space: nowrap;
  margin-bottom: 16px;
  font-weight: bold;
  line-height: 1;
  @media only screen and (max-width: 736px) {
    display: none;
  }
`;
const WorkplaceDetails = styled.div`
  flex: 1;
`;
const WorkplaceContent = styled.div`
  flex: 2;
`;

const RolesDetails = styled.div`
  margin: 4px 0;
`;

const Role = styled.div`
  margin: 4px 0;
`;

const Workplace = ({ title, duration, children, roles }) => (
  <WorkplaceLayout>
    <WorkplaceDetails>
      <WorkplaceDuration>{duration}</WorkplaceDuration>

      <RolesDetails>
        {roles.reverse().map((role, index) => (
          <React.Fragment key={role}>
            <Role
              style={
                index === 0
                  ? { fontWeight: 600 }
                  : { color: "var(--sub-title-color)" }
              }
            >
              {role}
            </Role>
          </React.Fragment>
        ))}
      </RolesDetails>
    </WorkplaceDetails>
    <WorkplaceContent>
      <WorkplaceTitle>{title}</WorkplaceTitle>
      {children}
    </WorkplaceContent>
  </WorkplaceLayout>
);

const WorkplaceSectionLayout = styled.div`
  padding-bottom: 16px;
  line-height: 1.4em;
`;
const WorkplaceSectionTitle = styled.div`
  color: var(--title-color);
  font-size: 16px;
  font-weight: 600;
  margin-bottom: 8px;
`;
const WorkplaceSection = ({ title, children }) => (
  <WorkplaceSectionLayout>
    {title ? <WorkplaceSectionTitle>{title}</WorkplaceSectionTitle> : null}
    <div>{children}</div>
  </WorkplaceSectionLayout>
);

const Paragraph = styled.div`
  margin: 16px 0;
  &:last-child {
    margin-bottom: 0;
  }
`;

const RiserMedia = () => (
  <Workplace title="Riser Media" duration="2009-2011" roles={["Web Developer"]}>
    <WorkplaceSection>
      <Paragraph>
        Architected and implemented dozens of projects for high profile clients
        on demanding timelines including:
      </Paragraph>
      <List>
        <ListItem>
          A web application built for National Geographic. A photo sharing
          website tailored to kids and is fully moderated by National Geographic
          producers. Full community support including ratings, comments &
          profiles. Image manipulation, offensive word censoring, tags,
          categories and metric based browsing, and a custom search engine all
          built on a robust HMVC architecture.
        </ListItem>
        <ListItem>
          The website for the TV Show Ugly Betty built completely with static
          site generation to meet strict performance requirements.
        </ListItem>
        <ListItem>The Sesame Street video games website.</ListItem>
        <ListItem>
          Regis & Kelly Lives Beautiful Babies Contest. Web application & site
          allowing viewers of Regis & Kelly to upload photos of their babies,
          vote and win a scholarship.
        </ListItem>
        <ListItem>USA Network Character Blog</ListItem>
        <ListItem>
          Pepsi Tropicana Tropolis - Website contained 3 animated worlds,
          several games, interactive quizzes and downloadable coloring pages and
          wallpapers, as well as a fully interactive area filled with
          information about the product for parents.
        </ListItem>
        <ListItem>
          Many other projects for other prolific clients such as People of
          Walmart, Disney & Beard Papa
        </ListItem>
      </List>
    </WorkplaceSection>
  </Workplace>
);

const RedOlive = () => (
  <Workplace title="Red Olive" duration="2007-2009" roles={["Web Developer"]}>
    <WorkplaceSection>
      <Paragraph>
        Built websites & interactive experiences for many clients; primarily
        front-end development. I worked with our in-house CMS and built many
        rich interactive Flash applications. I also skinned a few e-commerce
        systems.
      </Paragraph>
    </WorkplaceSection>
  </Workplace>
);

const CommunityLayout = styled.div`
  padding: 16px 0;
`;
const Community = () => (
  <CommunityLayout>
    <Columns>
      <Column>
        <WorkplaceSection title="Tech Conference Organizer">
          <div>
            Founded and co-organized ng-conf for several years, one of
            Utah&apos;s largest tech conferences & the official Angular
            conference.
          </div>
        </WorkplaceSection>
      </Column>
      <Column>
        <WorkplaceSection title="Public Speaking">
          <div>
            Spoken at several technical conferences including React Conf,
            GothamJS, DevLink, UtahJS, Cascadia, Utah Code Camp, Utah Open
            Source Conference x2 & Mountain West JS.
          </div>
        </WorkplaceSection>
      </Column>
      <Column>
        <WorkplaceSection title="Open Source Author">
          <div>
            Published several Open Source projects and collaborated directly
            with teams from large technology organizations such as IBM, Google &
            Facebook.
          </div>
        </WorkplaceSection>
      </Column>
    </Columns>
    <Columns>
      <Column>
        <WorkplaceSection title="Podcaster">
          <div>
            Alumni panelist of JavaScript Jabber podcast reaching over 1 million
            downloads. As well as a guest on several other podcasts such as the
            Shop Talk Show & the Hello World Road Trip.
          </div>
        </WorkplaceSection>
      </Column>
      <Column>
        <WorkplaceSection title="Local Tech Scene">
          <div>
            Attended, organized & presented at several local meetups,
            participated in local start-up competitions, sat on a few panels &
            taught at DevMountain. I also mentor several engineers locally
            fielding various questions & help remove roadblocks.
          </div>
        </WorkplaceSection>
      </Column>
      <Column>
        <WorkplaceSection title="Published Musician & Tinkerer">
          <div>
            Published music that has been downloaded/streamed more than 100,000
            times. Ask me about my current and past side projects, my non-profit
            work or my internet-of-things experiments.
          </div>
        </WorkplaceSection>
      </Column>
    </Columns>
  </CommunityLayout>
);

const Webflow = () => (
  <Workplace
    title="Webflow"
    duration="2017-Present"
    roles={[
      "Senior Software Engineer",
      "Staff Software Engineer",
      "Senior Staff Software Engineer",
    ]}
  >
    <WorkplaceSection>
      <List>
        <ListItem>
          Technical lead of Platform where we designed and implemented an
          extensible in house structurally edited programming language that
          powers all Webflow sites. My role at Webflow is best described as
          &apos;the backend of the front-end&apos;. I work on low-level
          primitives to help people create software without writing code.
        </ListItem>
        <ListItem>
          Upgrading Webflow to a plugin based architecture. This includes cross
          team coordination, designing technical primitives and abstractions
          with multiple audiences as well as generating interest & adoption to
          platformize Webflow.
        </ListItem>
        <ListItem>
          Design & implementation of a pluggable constraint engine to ensure end
          user designs are valid & safe to compile.
        </ListItem>
        <ListItem>
          Contributions to the Webflow renderer which includes CSS, JavaScript &
          DOM compilation from end-user created data structures.
        </ListItem>
        <ListItem>
          Design & implementation of a declarative & pluggable type data
          structure that has the ability to render interfaces to update various
          combinations of the types for use in site design primitives.
        </ListItem>
      </List>
    </WorkplaceSection>
  </Workplace>
);

const Domo = () => (
  <Workplace
    title="Domo"
    duration="2011-2017"
    roles={[
      "Associate Software Engineer",
      "Software Engineer",
      "Open Web Architect",
      "Entrepreneur in Residence",
    ]}
  >
    <WorkplaceSection>
      <List>
        <ListItem>
          Started at Domo early in the company&apos;s formation. I was part of a
          small team of engineers working directly with the CEO that launched
          the Domo brand, building their marketing site & press release pages.
          The technical foundation for this project powered Domo&apos;s
          marketing presence for several years following.
        </ListItem>
        <ListItem>
          Implemented and influenced early prototypes used to raise early rounds
          of funding ($33 million) & validated product vision.
        </ListItem>
        <ListItem>
          Designed and implemented Domo&apos;s flagship product&apos;s UI
          architecture & implemented several features. This included technology
          selection, evangelization & mentoring as well as setting up build
          tools, continuous integration, internationalization & testing
          frameworks. As the engineering team grew from a handful of us to
          nearly two hundred in the course of a few years I helped with
          onboarding, adopting technology in a rapidly evolving ecosystem, and
          participated in product ideation & implementation.
        </ListItem>
        <ListItem>
          Contributed to Domo&apos;s native iOS offering. Then headed a
          responsive mobile web offering with many responsibilities including
          laying down build tools, continuous integration and implementing
          focused and fast mobile specific versions of key Domo features. I
          mentored a highly efficient team of engineers working closely with
          product managers and user experience designers.
        </ListItem>
        <ListItem>
          Recruited & interviewed many candidates. Implemented fun side projects
          involving industrial sized train horns, whistles, drones and more
          lights and disco balls than you&apos;d find at a Skrillex concert.
          Started and ran team building events that continue in Domo&apos;s
          engineering department, such as quarterly hack nights.
        </ListItem>
      </List>
    </WorkplaceSection>
  </Workplace>
);

const IconsLayout = styled.div`
  display: flex;
  justify-content: flex-end;
`;

const IconLink = styled.a`
  width: 16px;
  height: 16px;
  margin-left: 8px;
  color: var(--title-color);
  text-decoration: none;
`;

const Icons = () => (
  <IconsLayout>
    <IconLink
      href="https://github.com/iammerrick"
      target="_blank"
      rel="noopener noreferrer"
    >
      <Github width={16} height={16} />
    </IconLink>
    <IconLink
      href="https://twitter.com/iammerrick"
      target="_blank"
      rel="noopener noreferrer"
    >
      <Twitter width={16} height={16} />
    </IconLink>
  </IconsLayout>
);

const ContactItemLayout = styled.div`
  display: flex;
  margin: 8px 0;
`;

const ContactLabel = styled.div`
  padding-right: 8px;
  text-transform: uppercase;
  width: 180px;
  font-weight: 600;
  text-align: right;
  color: var(--sub-title-color);
  @media only screen and (max-width: 736px) {
    width: inherit;
  }
`;

const ContactValue = styled.div`
  color: var(--title-color);
`;

const ContactItem = ({ label, value }) => (
  <ContactItemLayout>
    <ContactLabel>{label}</ContactLabel>
    <ContactValue>{value}</ContactValue>
  </ContactItemLayout>
);

const ContactLink = styled.a`
  color: var(--title-color);
  text-decoration: none;
`;
const Contact = () => (
  <div>
    <ContactItem
      label="Email"
      value={
        <ContactLink
          href="mailto:merrick.christensen@gmail.com"
          target="_blank"
          rel="noopener noreferrer"
        >
          merrick.christensen@gmail.com
        </ContactLink>
      }
    />
    <ContactItem label="Phone" value="801.243.6679" />
    <ContactItem
      label="Website"
      value={
        <ContactLink
          href="http://merrickchristensen.com"
          target="_blank"
          rel="noopener noreferrer"
        >
          merrickchristensen.com
        </ContactLink>
      }
    />
    <ContactItem
      label={<Icons />}
      value={
        <ContactLink
          href="http://twitter.com/iammerrick"
          target="_blank"
          rel="noopener noreferrer"
        >
          iammerrick
        </ContactLink>
      }
    />
  </div>
);

const HeaderLayout = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding-bottom: 32px;
  @media only screen and (max-width: 736px) {
    flex-direction: column;
    align-items: flex-start;
    padding-bottom: 16px;
  }
`;

const Title = styled.div`
  font-size: 1.4em;
  padding-top: 8px;
  color: var(--sub-title-color);
`;

const TitleLogo = styled.div`
  padding-bottom: 8px;
`;
const Header = () => (
  <HeaderLayout>
    <TitleLogo>
      <div>
        <Logo height={48} />
      </div>
      <Title>Engineer</Title>
    </TitleLogo>
    <Contact />
  </HeaderLayout>
);

const Footer = styled.div`
  display: none;
  @media print {
    position: fixed;
    bottom: 0;
    display: block;
    color: var(--body-text-muted-color);
  }
`;

const ResumeLayout = styled.div`
  font: 14px HelveticaNeue, Helvetica, sans-serif;
  max-width: 992px;
  margin: auto;
  padding: 32px 16px;
  color: var(--body-text-color);
`;
const SectionHeader = styled.div`
  font-size: 24px;
  font-weight: bold;
  color: var(--title-color);
`;

class Resume extends React.Component {
  render() {
    return (
      <PageLayout slug={this.props.location.pathname}>
        <ResumeLayout>
          <Header />
          <div>
            <SectionHeader>Experience</SectionHeader>
            <Webflow />
            <Domo />
            <RiserMedia />
            <RedOlive />
            <SectionHeader>Skills & Technology</SectionHeader>
            <Skills />
            <SectionHeader>Community</SectionHeader>
            <Community />
          </div>
          <Footer>Available online at merrickchristensen.com/resume</Footer>
        </ResumeLayout>
      </PageLayout>
    );
  }
}

export default Resume;
